import React, { Component } from 'react'
import enterIcon from './../enter-icon.png'

import { Link } from 'react-router-dom'
// import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component'

import { throttle } from 'lodash'

class Home extends Component {

  constructor(props) {
    super(props)
    // this.handleScroll = this.handleScroll.bind(this)
    this.handleScroll = throttle(this.scrollHandler, 250).bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
    if (sessionStorage.getItem('homeScrollY')) {
      requestAnimationFrame(() => {
        window.scrollTo(0, sessionStorage.getItem('homeScrollY'))
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  scrollHandler() {
    // console.log('HOME scrolling...', window.scrollY)
    sessionStorage.setItem('homeScrollY', window.scrollY)
  }

  render() {
    // console.log('HOME props:')
    // console.log(this.props)
    return(
      <div>
        {/* <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1 className="App-title">Welcome to React</h1>
        </header>
        <p className="App-intro">
          To get started, edit <code>src/App.js</code> and save to reload.
        </p> */}
        { this.props.galleries.map(gal => {
          const date = new Date(gal.date).toDateString()


          // does the gallery have an ordered 'imagesList'?
          if (gal.imagesList) {
            let orderedImages = []
            const images = gal.images
            // This is the list of positioned images according to the admin
            // for each image ID, we need to remove that image from 'images'
            gal.imagesList.forEach(_id => {
              const imageIndex = images.findIndex(img => img._id === _id)
              if (imageIndex >= 0) {
                let image = images.splice(imageIndex, 1)[0]
                orderedImages.push(image)
              }
            })
            orderedImages.concat(images)
            gal.images = orderedImages
          }

          return (
            <div key={gal._id} className="home-gallery-preview">
              <h2><Link to={`/gallery/${gal._id}`}>{gal.title}<img src={enterIcon} className="enter-icon" alt='' title="View gallery"/></Link></h2>
              <p className="home-gallery-date">{date}</p>
              <p className="home-gallery-length">{gal.images.length} image{gal.images.length > 1 ? 's' : ''}</p>
              <div className="home-gallery-images-wrapper" style={{  }}>
                {/* Images: */}
                { gal.images.map((image, index) => {
                  if (index > 2) return null
                  // let styleS = {position: 'absolute'}
                  // let styleS = {}
                  // if (index === 1) styleS.transform = 'rotate(15deg)'
                  // if (index === 2) styleS.transform = 'rotate(30deg)'
                  const fileNameAsUrl = image.fileName.split('.')[0]
                  // fileNameAsUrl.pop()
                  return (
                    <div key={image._id} className="home-gallery-image-wrapper" >
                      <Link to={`/image/${fileNameAsUrl}`} >
                        <img
                          src={`/api/images/${image.fileName}?galleryview=500`} className="home-gallery-image"
                          alt={image.title}
                        />
                        {/* <LazyLoadImage
                          // scrollPosition={this.props.scrollPosition}
                          threshold="250"
                          src={`/api/images/${image.fileName}?galleryview=500`} className="home-gallery-image"
                          style={styleS}
                          /> */}
                        </Link>
                      </div>
                  )}
                )}
                {/* transform: rotate(-10deg) etc ... */}
              </div>
            </div>
          )
        })}
      </div>
  )}
}

export default Home
// export default trackWindowScroll(Home)

import React, { Component } from 'react'
// import logo from './../logo.svg'

import { Link } from 'react-router-dom'
// import { LazyLoadImage } from 'react-lazy-load-image-component'

class ImageView extends Component {

  componentDidMount() {
    window.scrollTo(0,0)
  }

  render() {
    const galleries = this.props.galleries
    if (!galleries.length) return <div>Loading...</div>
    // const theImage = galleries.reduce((images, gallery) => images.concat(gallery.images), [])
    // .find(image => image.fileName.split('.')[0] === this.props.match.params.imageName)

    let theImage
    const theGallery = galleries.find(gal => {
      const imageMatch = gal.images
        .find(img => img.fileName.split('.')[0] === this.props.match.params.imageName)
      if (imageMatch) {
        theImage = imageMatch
        return true
      } return false
    })
    
    if (!theImage) return <div>Image not found.</div>
    console.log('ImageView: Viewing image:')
    console.log(theImage)
    
    return (
      <div>
        <div>
          <Link to='/'>HOME</Link>
          <span> / </span>
          <Link to={`/gallery/${theGallery._id}`}>{theGallery.title}</Link>
          <span> / </span>
          <Link to={`/image/${theImage.fileName.split('.')[0]}`}>{theImage.title || '[untitled]'}</Link>
        </div>
        <h1>{theImage.title}</h1>
        <div>
          <a href={`/api/images/${theImage.fileName}`}>
            <img src={`/api/images/${theImage.fileName}?galleryview=1500`} alt={theImage.title} style={{width: '100%', maxWidth: '1500px', border: '1em solid white', cursor: 'zoom-in' }} />
          </a>
        </div>
      </div>
    )
  }
}

export default ImageView

import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import './App.css'

import Home from './components/Home'
import GalleryView from './components/GalleryView'
import ImageView from './components/ImageView'

const PageNotFound = () => (<div>404 Page Not Found</div>)

class App extends Component {
  state = { galleries: [] }
  componentDidMount() {

    fetch('/api/galleries/order')
      .then(res => res.json())
      .then(galleriesOrderedIDs => {

        fetch('/api/galleries/frontpage')
          .then(res => res.json())
          .then(json => {
            console.log('Loaded:')
            console.log(json)

            let galleriesArray = []
            Object.keys(json).forEach(key => galleriesArray.push(json[key]))

            // order them according to the order list...
            let orderedGalleries = []
            // This is the list of positioned galleries according to the admin
            galleriesOrderedIDs.list.forEach(_id => {
              const galleryIndex = galleriesArray.findIndex(gal => gal._id === _id)
              if (galleryIndex >= 0) {
                orderedGalleries.push(galleriesArray.splice(galleryIndex, 1)[0])
              }
            })
            this.setState({ galleries: orderedGalleries.concat(galleriesArray) })
          })

      })

  }
  render() {
    return (
      <div className="App">
        <Switch>
          {/* <Route path='/' exact={true} component={Home} galleries={this.state.galleries}/> */}
          <Route path='/' exact render={(props) => <Home galleries={this.state.galleries} {...props} />} />
          {/* <Route path='/gallery/:galleryID' exact={true} component={GalleryView} galleries={this.state.galleries} /> */}
          <Route path='/gallery/:galleryID' exact render={(props) => <GalleryView galleries={this.state.galleries} {...props} />} />
          <Route path='/image/:imageName' exact render={props => <ImageView galleries={this.state.galleries} {...props} />} />
          <Route path='*' component={PageNotFound} />
        </Switch>
      </div>
    );
  }
}

export default App

import React, { Component } from 'react'
// import logo from './../logo.svg'

import { Link } from 'react-router-dom'
// import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component'

class GalleryView extends Component {

  componentDidMount() {
    window.scrollTo(0,0)
  }

  render() {
    const gallery = this.props.galleries.find(gal => gal._id === this.props.match.params.galleryID)
    if (!gallery && this.props.galleries.length) return <div>Gallery not found.</div>
    if (!this.props.galleries.length) return <div>Loading...</div>
    // const scrollPosition = this.props.scrollPosition
    return (
      <div>
        <div><Link to='/'>HOME</Link></div>
        <h1>{gallery.title}</h1>
        <div className="home-gallery-images-wrapper gallery-images-wrapper">
          { gallery.images.map(image => {
            const fileNameAsUrl = image.fileName.split('.')[0]
            return (
              <div className="home-gallery-image-wrapper gallery-image-wrapper" key={image._id}>
                <Link to={`/image/${fileNameAsUrl}`} >
                  <img
                    src={`/api/images/${image.fileName}?galleryview=500`}
                    className="home-gallery-image"
                    alt={image.title}
                  />
                  {/* <LazyLoadImage
                  scrollPosition={scrollPosition}
                    src={`/api/images/${image.fileName}?galleryview=500`}
                    className="home-gallery-image"
                  /> */}
                </Link>
              </div>
            )

          })}
        </div>
      </div>
    )
  }
}

// export default trackWindowScroll(GalleryView)
export default GalleryView
